import React from 'react';

import PageHome from './PageHome'
import JewelryGroup from './JewelryGroup'
import PageRealEstateGroup from './PageRealEstateGroup'
import PageRealEstateDetail from './PageRealEstateDetail'

import { BrowserRouter, Route } from "react-router-dom";

import store from '../redux/storeConfig'
import { Provider } from 'react-redux'


class App extends React.Component {
  render(){
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Route exact path="/" render={(props) => <PageHome {...props} />} />
          <Route exact path="/jewelry-group" render={(props) => <JewelryGroup {...props} />} />
          <Route path="/real-estate-group" render={(props) => <PageRealEstateGroup {...props} />}/>

          <Route path="/hsbc-building*" render={(props) => <PageRealEstateDetail {...props} pid='hsbc-building' />} />
          <Route path='/hotel-de-vendome*' render={(props) => <PageRealEstateDetail {...props} pid='hotel-de-vendome' />} />
          <Route path="/villa-rose-pierre*" render={(props) => <PageRealEstateDetail {...props} pid='villa-rose-pierre' />} />
          <Route path="/grand-hotel-du-cap-ferret*" render={(props) => <PageRealEstateDetail {...props} pid='grand-hotel-du-cap-ferret' />} />
          <Route path="/reef-island*" render={(props) => <PageRealEstateDetail {...props} pid='reef-island' />} />
          <Route path="/grand-hill*" render={(props) => <PageRealEstateDetail {...props} pid='grand-hill' />} />
          <Route path="/private-museum*" render={(props) => <PageRealEstateDetail {...props} pid='private-museum' />} />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;